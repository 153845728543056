require("vue");

import { createApp } from 'vue';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // 0からiの範囲でランダムなインデックスを選ぶ
        const j = Math.floor(Math.random() * (i + 1));
        // i番目とj番目の要素を交換する
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

// js call method
function top_pickup_area_randomize(tgt_dom_id, pageInfos){

    const Cal = {
        data() {
            return {
                page: null,
                errored: false,
            }
        },
        methods: {
            call_api: function(){
                var vueObj = this;

                if(pageInfos.length === 0){
                    return false;
                }
                const shuffledInfos = shuffleArray(pageInfos);
                vueObj.page = shuffledInfos[0];

                return true;
            }
        },
        computed: {
        },
        mounted: function(){
            // console.log("top_pickup_area_randomize mounted.");
            var vueObj = this;
            vueObj.call_api();
        }
    };
    const app = createApp(Cal).mount(tgt_dom_id);
}

// ここが有効になればグローバルに使えるようになる。
window.top_pickup_area_randomize = top_pickup_area_randomize;
